.App-logo {
  /*
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
  */
}

.App-header {
  /*
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  */
}

.App-link {
  /*
  color: #61dafb;
  */
}

.experience-block-header {
  margin: 0 0 0.25em;
}

@keyframes App-logo-spin {
  /*
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
  */
}

@import "~normalize.css";
@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";